import { useState } from "react";
import styles from './Tabs.module.css';

export default function Tabs({ tabs }) {
  const [selectedId, setSelectedId] = useState(tabs[0].id);
  const selectedTab = tabs.find(tab => tab.id === selectedId);

  if (selectedTab === undefined) {
    setSelectedId(tabs[0].id)
    return (
      <>
      </>
    );
  }

  const Component = selectedTab.component;
  return (
    <div className={styles.container}>
      <div className={styles.tabs_block}>

        {tabs.map(tab => (
          <button
            key={tab.id}
            className={selectedId === tab.id ? styles.tabs + ' ' + styles.active_tab : styles.tabs}
            onClick={() => setSelectedId(tab.id)}
          >
            {tab.short_title}
          </button>
        ))}
      </div>
      <div className={styles.content_container}>
        <div key={selectedId} className={styles.content}>
          <h3>{selectedTab.title}</h3>
          <Component data={selectedTab.data} testDevice={selectedTab.testDevice} />
        </div>
      </div>
    </div>
  );
}
