import { useTranslation } from 'react-i18next';

export default function SpaceHeater({ data, testDevice }) {
  const { t } = useTranslation();

  return (
    <>
      <table border="1" cellSpacing="1" cellPadding="10">
        <tbody>
          <tr>
            <td>{t('api.last_connection')}</td>
            <td style={{backgroundColor: data.latest_update_status}}>{new Date(data.latest_update).toLocaleString()}</td>
          </tr>
          <tr>
            <td colSpan="2" align="center">{t('api.phase_1')}</td>
          </tr>
          <tr>
            <td>{t('api.relay.title')}</td>
            <td>{t('api.relay.' + data.phase_1.relay_status)}</td>
          </tr>
          <tr>
            <td>{t('api.consumption_now')}</td>
            <td>{data.phase_1.power_consumption.now !== undefined ? data.phase_1.power_consumption.now + ' W' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td>{t('api.total_consumption_last_24h')}</td>
            <td>{data.phase_1.power_consumption['24h'] !== undefined ? data.phase_1.power_consumption['24h'] + ' kWh' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td>{t('api.total_consumption_last_7d')}</td>
            <td>{data.phase_1.power_consumption['7d'] !== undefined ? data.phase_1.power_consumption['7d'] + ' kWh' : t('api.not_available')}</td>
          </tr>

          <tr>
            <td colSpan="2" align="center">{t('api.phase_2')}</td>
          </tr>
          <tr>
            <td>{t('api.relay.title')}</td>
            <td>{t('api.relay.' + data.phase_2.relay_status)}</td>
          </tr>
          <tr>
            <td>{t('api.consumption_now')}</td>
            <td>{data.phase_2.power_consumption.now !== undefined ? data.phase_2.power_consumption.now + ' W' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td>{t('api.total_consumption_last_24h')}</td>
            <td>{data.phase_2.power_consumption['24h'] !== undefined ? data.phase_2.power_consumption['24h'] + ' kWh' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td>{t('api.total_consumption_last_7d')}</td>
            <td>{data.phase_2.power_consumption['7d'] !== undefined ? data.phase_2.power_consumption['7d'] + ' kWh' : t('api.not_available')}</td>
          </tr>

          <tr>
            <td colSpan="2" align="center">{t('api.phase_3')}</td>
          </tr>
          <tr>
            <td>{t('api.relay.title')}</td>
            <td>{t('api.relay.' + data.phase_3.relay_status)}</td>
          </tr>
          <tr>
            <td>{t('api.consumption_now')}</td>
            <td>{data.phase_3.power_consumption.now !== undefined ? data.phase_3.power_consumption.now + ' W' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td>{t('api.total_consumption_last_24h')}</td>
            <td>{data.phase_3.power_consumption['24h'] !== undefined ? data.phase_3.power_consumption['24h'] + ' kWh' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td>{t('api.total_consumption_last_7d')}</td>
            <td>{data.phase_3.power_consumption['7d'] !== undefined ? data.phase_3.power_consumption['7d'] + ' kWh' : t('api.not_available')}</td>
          </tr>

          <tr>
            <td colSpan="2" align="center">{t('api.space_heater.room_temperature')}</td>
          </tr>
          <tr>
            <td>{t('api.now')}</td>
            <td>{data.room_temperature.now ? data.room_temperature.now + '°C' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td>{t('api.max_last_24h')}</td>
            <td>{data.room_temperature.max_24h ? data.room_temperature.max_24h + '°C' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td>{t('api.max_last_7d')}</td>
            <td>{data.room_temperature.max_7d ? data.room_temperature.max_7d + '°C' : t('api.not_available')}</td>
          </tr>

          <tr>
            <td colSpan="2" align="center">{t('api.space_heater.device_temperature')}</td>
          </tr>
          <tr>
            <td>{t('api.now')}</td>
            <td>{data.device_temperature.now ? data.device_temperature.now + '°C' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td>{t('api.max_last_24h')}</td>
            <td>{data.device_temperature.max_24h ? data.device_temperature.max_24h + '°C' : t('api.not_available')}</td>
          </tr>
          <tr>
            <td>{t('api.max_last_7d')}</td>
            <td>{data.device_temperature.max_7d ? data.device_temperature.max_7d + '°C' : t('api.not_available')}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <button onClick={() => testDevice(data.id) }>Test device/Close relay</button>
    </>
  );
}
