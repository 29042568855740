import React from 'react';

import { withTranslation } from 'react-i18next';

import Tabs from "../Tabs/Tabs";
import WaterHeater from "../../WaterHeater";
import SpaceHeater from "../../SpaceHeater";
import Router from "../../Router";
import Household from "../../Household";

class ShowData extends React.Component {
  render() {
    if (! this.props.data) {
      return;
    }

    const tabs = []

    if (0 && this.props.data.routers) {
      this.props.data.routers.forEach((item) => {
        tabs.push({
          'id': 'tab-' + item.id,
          'title': this.props.t('api.router.tab_title') + ' (' + item.short_id + ')',
          'short_title': (
            <>
              {this.props.t('api.router.tab_title')}
              <br />
              ({item.short_id})
            </>
          ),
          'component': Router,
          'data': item,
        });
      });
    }

    if (0 && this.props.data.household_measurement) {
      tabs.push({
        'id': 'tab-hh',
        'title': this.props.t('api.household.tab_title'),
        'short_title': this.props.t('api.household.tab_title'),
        'component': Household,
        'data': this.props.data.household_measurement,
      });
    }

    if (this.props.data.water_heaters) {
      this.props.data.water_heaters.forEach((item) => {
        tabs.push({
          'id': 'tab-' + item.id,
          'title': this.props.t('api.water_heater.tab_title') + ' (' + item.short_id + ')',
          'short_title': (
            <>
              {this.props.t('api.water_heater.tab_title')}
              <br />
              ({item.short_id})
            </>
          ),
          'component': WaterHeater,
          'data': item,
          'testDevice': this.props.testDevice,
        });
      });
    }

    if (this.props.data.space_heaters) {
      this.props.data.space_heaters.forEach((item) => {
        tabs.push({
          'id': 'tab-' + item.id,
          'title': this.props.t('api.space_heater.tab_title') + ' (' + item.short_id + ')',
          'short_title': (
            <>
              {this.props.t('api.space_heater.tab_title')}
              <br />
              ({item.short_id})
            </>
          ),
          'component': SpaceHeater,
          'data': item,
          'testDevice': this.props.testDevice,
        });
      });
    }

    if (! tabs) {
      return;
    }

    /* TODO: no data found/no tabs/... */
    /* TODO: render error(s) */

    return (
      <div>
        Loaded: {this.props.data['fetched'].toLocaleString()}
        <Tabs tabs={tabs}/>
      </div>
    )
  }
}

export default withTranslation()(ShowData);

/*
          'title': (
              <>
                {this.props.t('api.router.tab_title')}
                <br />
                ({item.short_id})
              </>
          ),
*/
