import LoadingSpinner from "../LoadingSpinner/LoadingSpinner.tsx";

function WithLoading(Component) {
  return function WithLoadingComponent({ isLoading, ...props }) {
    if (isLoading) {
      return <LoadingSpinner isLoading={true} />;
    } else {
      return <Component {...props} />;
    }
  };
}

export default WithLoading;
