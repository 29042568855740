import styles from './ShowError.module.css'

export default function showError({ error_msg }) {
  if (! error_msg) {
    return;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {error_msg}
      </div>
    </div>
  );
}
