import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import queryStringLang from './queryStringLang'

const languageDetector = new LanguageDetector();
languageDetector.addDetector(queryStringLang);

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    fallbackLng: "en",
    debug: true,
    detection: {
      order: ['queryStringLang'],
    },
    supportedLngs: ["en", "nl", "fr"],
    backend: {
      loadPath: "/assets/{{ns}}/{{lng}}.json",
    },

    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });

export default i18n;
