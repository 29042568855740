import React from 'react';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { useTranslation, withTranslation } from 'react-i18next';

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner.tsx";
import styles from './AwsAuth.module.css';

function LoginButton(props) {
  const { t } = useTranslation();

  return (
    <button className={styles.Login_link} onClick={props.onClick}>
      {t('login.label')}
    </button>
  );
}

function LogoutButton(props) {
  const { t } = useTranslation();

  return (
    <button onClick={props.onClick}>
      {t('logout.label')}
    </button>
  );
}


class AwsAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "loading": false,
      "showForm": false,
    };
    this.usernameInput = React.createRef();
    this.passwordInput = React.createRef();
  }

  componentDidMount() {
    this.setupCognitoPool();
    this.checkLogin();
  }

  componentDidUpdate(prevProps) {
    if (! this.props.isLoggedIn && prevProps.isLoggedIn) {
      // We were logged in but we no longer are..
      // This may have been caused by:
      //   - logout button being pressed
      //   - something else caused the logout (access token no longer valid? ...?)
      if (this.cognitoUser) {
        // something else caused the logout
        if (this.cognitoUser.signInUserSession && this.cognitoUser.signInUserSession.isValid()) {
          // the session should still be valid...
          // could be some time-drift on the client? (i.e. still 'valid' on the client but
          // expired on the server?)
          // -> force a refresh
          const refreshToken = this.cognitoUser.signInUserSession.getRefreshToken();
          const obj = this;
          this.setState({"loading": true});
          this.cognitoUser.refreshSession(
            refreshToken,
            (err, res) => {
              if (err) {
                obj._logout();
                obj.setState({"loading": false});
                console.log(err);
                return;
              }
              obj.props.setLoggedIn(true);
              obj.props.setAccessToken(res.accessToken.getJwtToken());
              obj.setState({"loading": false});
            },
            {},
          );
        }
        else {
          this.checkLogin();
        }
      }
    }
  }

  setupCognitoPool() {
    const domain = window.location.hostname;
    this.storage = new AmazonCognitoIdentity.CookieStorage({domain: domain, secure: false});

    const poolData = {
      UserPoolId: 'eu-west-1_oat3ihxe6',
      ClientId: '3rfoe6jlqmcmf4if76hup65am7',
      Storage: this.storage,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    this.userPool = userPool;
  }

  checkLogin() {
    const currentUser = this.userPool.getCurrentUser();
    if (! currentUser) {
      this._logout();
      return;
    }

    // Get a session for currentUser:
    // - if idToken and accessToken (stored in cookies) is still valid these
    //   will be returned.
    // - if idToken/accessToken is expired the refreshToken is used to refreh
    //   the tokens.
    this.setState({"loading": true});
    const obj = this;
    currentUser.getSession((err, res) => {
      if (err) {
        obj._logout();
        obj.setState({"loading": false});
        console.log(err);
        return;
      }

      obj.cognitoUser = currentUser;
      obj.props.setLoggedIn(true);
      obj.props.setAccessToken(res.accessToken.getJwtToken());
      obj.setState({"loading": false});
    });
  }

  _logout() {
    if (this.cognitoUser) {
      this.cognitoUser.clearCachedTokens();
    }

    this.cognitoUser = null;
    this.props.setLoggedIn(false);
    this.props.setAccessToken(null);
  }

  handleLoginClick() {
    this.setState({"showForm": true});
  }

  handleLogoutClick() {
    const currentUser = this.cognitoUser;
    if (currentUser) {
      currentUser.revokeTokens();
    }
    this._logout();
  }

  handleSubmit(e) {
    e.preventDefault();
    const auth_user = this.usernameInput.current.value;
    const auth_pass = this.passwordInput.current.value;

    const authenticationData = {
      Username: auth_user,
      Password: auth_pass,
    };
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

    const userData = {
      Username: auth_user,
      Pool: this.userPool,
      Storage: this.storage,
    };
    this.cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    const obj = this;
    this.setState({"loading": true});
    this.cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function(result) {
        const accessToken = result.getAccessToken().getJwtToken();
        obj.setState({"showForm": false});
        obj.setState({"loading": false});
        obj.props.setLoggedIn(true);
        obj.props.setAccessToken(accessToken);
      },
      onFailure: function(err) {
        alert(err.message || JSON.stringify(err));
        obj.setState({"loading": false});
        obj.props.setLoggedIn(false);
        obj.props.setAccessToken(null);
      },
    });
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner isLoading={true} />;
    }

    if (this.props.isLoggedIn) {
      return (
        <div>
          <LogoutButton onClick={this.handleLogoutClick.bind(this)} />
        </div>
      );
    }

    if (this.state.showForm) {
      return (
        <div>
          <h1>{this.props.t('login_header.label')}</h1>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <label>
              <p>{this.props.t('login_user.label')}</p>
              <input type="text" ref={this.usernameInput} />
            </label>
            <label>
              <p>{this.props.t('login_password.label')}</p>
              <input type="password" ref={this.passwordInput} />
            </label>
            <div>
              <button type="submit">{this.props.t('login_button.label')}</button>
            </div>
          </form>
        </div>
      );
    }

    return (
      <LoginButton onClick={this.handleLoginClick.bind(this)} />
    );
  }
}

export default withTranslation()(AwsAuth);
