const queryStringLang = {
  // based on https://github.com/i18next/i18next-browser-languageDetector/blob/master/src/browserLookups/querystring.js
  name: 'queryStringLang',

  lookup(options) {
    let found;

    if (typeof window !== 'undefined') {
      let { search } = window.location;
      if (!window.location.search && window.location.hash && window.location.hash.indexOf('?') > -1) {
        search = window.location.hash.substring(window.location.hash.indexOf('?'));
      }
      const query = search.substring(1);
      const params = query.split('&');
      for (let i = 0; i < params.length; i++) {
        const pos = params[i].indexOf('=');
        if (pos > 0) {
          const key = params[i].substring(0, pos);
          if (key === "lang") {
            found = params[i].substring(pos + 1);
          }
        }
      }
    }

    switch (found) {
      case 'be-nl':
         return 'nl';
      case 'be-fr':
        return 'fr';
      default:
        // do nothing
    }
    return found;
  },
};

export default queryStringLang
