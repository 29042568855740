import styles from "./LoadingSpinner.module.css";

export type LoadingSpinnerProps = {
  isLoading: boolean;
};

const LoadingSpinner = ({
  isLoading = true,
}: LoadingSpinnerProps) => {
  if (!isLoading) return null;
  return (
    <div className={styles.loader}></div>
  );
};

export default LoadingSpinner;
